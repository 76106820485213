/* eslint-env browser */
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Container, Segment } from "semantic-ui-react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import DocTitle from "../../components/DocTitle";
import { EntitySearchObj as EntitySearch } from "../../components/EntitySearch";
import logger from "../../services/logger";

function onError(rsp) {
  logger.error("Error: ", rsp); // TODO: Handle errors properly
}

export class EntityManagementObj extends Component {
  constructor(props) {
    super(props);
    this.clearSelectEntity = this.clearSelectEntity.bind(this);
    this.handleChangeInstitution = this.handleChangeInstitution.bind(this);
    this.handleSelectEntity = this.handleSelectEntity.bind(this);
  }

  componentWillUnmount() {
    this.clearSelectEntity();
  }

  clearSelectEntity() {
    const { props } = this;
    props.dispatch({
      type: "ENT_MGMT_CLEAR_ENTITY"
    });
  }

  handleSelectEntity(value) {
    const { props } = this;
    props.dispatch({
      type: "ENT_MANAGEMENT_SELECT_ENTITY",
      value
    });
  }

  handleChangeInstitution(value) {
    const { dispatch } = this.props;
    dispatch({
      type: "ENT_MGMT_SELECT_INST",
      value
    });
  }

  render() {
    const { selectedEntityUuid } = this.props;

    if (selectedEntityUuid) {
      return (
        <Redirect to={`/credit_management/entity/${selectedEntityUuid}`} />
      );
    }

    return (
      <DocTitle title="Search - BLAST Portal">
        <h1 className="mb-6">Entity Management</h1>
        <Container className="leftAlign" fluid textAlign="left">
          <Segment>
            <EntitySearch
              selectedEntityCallback={this.handleSelectEntity}
              selectedInstitutionCallback={this.handleChangeInstitution}
              isSelectOnNavigation={false}
              allowAdd
              onError={onError}
            />
          </Segment>
        </Container>
      </DocTitle>
    );
  }
}

EntityManagementObj.propTypes = {
  dispatch: PropTypes.func.isRequired,
  selectedEntityUuid: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
  selectedEntityUuid: state.EntityManagementReducer.selectedEntityUuid,
  selectedInstitution: state.EntityManagementReducer.selectedInstitution
});

const EntityManagement = connect(mapStateToProps)(EntityManagementObj);

export default EntityManagement;
